import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import { authGuard } from '../auth/authGuard'

Vue.use(Router)

export default new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/stocks',
      name: 'home',
      component: Home
    },
    // {
    //   path: '/profile',
    //   name: 'profile',
    //   component: () => import('../views/Profile.vue'),
    //   beforeEnter: authGuard
    // },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/About.vue')
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('../views/Signup.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Login.vue')
    },
    {
      path: '/upgrade',
      name: 'upgrade',
      component: () => import('../views/Upgrade.vue')
    },
    {
      path: '/success',
      name: 'PaymentSuccess',
      component: () => import('../views/PaymentSuccess.vue')
    },
    {
      path: '/trades',
      name: 'Trades',
      component: () => import('../views/DisplayTrades.vue')
    },
    {
      path: '/',
      name: 'Landing',
      component: () => import('../views/Landing.vue')
    },
    {
      path: '/landing',
      name: 'Landing',
      component: () => import('../views/Landing.vue')
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () => import('../views/Contact.vue')
    },
  ]
})
