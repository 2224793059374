<template>
  <v-app>
    <Header :key="hack" v-if="$route.path !== '/'"/>
    <v-main>
      <router-view :key="$route.fullPath" @rerender="rerender"></router-view>
    </v-main>
    <Footer v-if="$route.path === '/stocks'" />
  </v-app>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import { store } from "./store/store";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },

  data: () => ({
    hack: 1,
    store,
  }),
  computed: {},
  methods: {
    rerender() {
      this.hack += 1;
    },
  },
};
</script>
