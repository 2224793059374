<template>
  <v-alert
    v-if="timeFromDB"
    border="left"
    colored-border
    type="info"
    elevation="2"
  >
    <strong>Prediction made:</strong> {{ newYork }} <br/> <strong>For:</strong> {{ newYorkPlus30 }}
  </v-alert>
</template>

<script>
import moment from "moment-timezone";
export default {
  props: {
    timeFromDB: String,
  },
  data: () => ({}),
  computed: {
    momentDate() {
      return moment.utc(this.timeFromDB);
    },
    newYork() {
      return this.momentDate.clone().tz('America/New_York').format('h:mm a z')
    },
    newYorkPlus30() {
      return this.momentDate.clone().add(30, 'minutes').tz('America/New_York').format('h:mm a z')
    }
  },
  mounted() {
  },
};
</script>