import axios from 'axios'

const base =
  process.env.NODE_ENV === 'production'
    ? 'https://api.tealeaf.live'
    : 'http://localhost:3000'

export default {
  async getUserData(accessToken) {
    const res = await axios.post(
      `${base}/getuserdata`,
      {
        "":""
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    return res.data
  },
  async updateUserName(accessToken, name) {
    const res = await axios.post(
      'fix this please/getuserdata',
      {
        name
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    return res.data
  },
  async getCompanyUsers(accessToken, companyName) {
    const res = await axios.post(
      `${base}/getcompanyusers`,
      {
        companyName
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    return res.data
  },
  async attemptSignup(email, password) {
    const res = await axios.post(
      `${base}/attemptSignup`,
      {
        email: email,
        password: password
      },
      {
        headers: {
          Authorization: 'application/json'
        }
      }
    )
    return res.data
  },
  async login(email, password) {
    const res = await axios.post(
      `${base}/login`,
      {
        email,
        password
      }
    )
    .catch(err => {
      throw err
    })
    return res.data
  },
  logout(){
    window.$cookies.remove('token')
    window.location.href = window.location.origin
  }
}
