import axios from 'axios'

const base =
  process.env.NODE_ENV === 'production'
    ? 'https://api.tealeaf.live'
    : 'http://localhost:3000'

export default {
  async getCurrentData(accessToken) {
    const res = await axios.get(
      `${base}/currentdata`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    return res.data
  },

  async getDayHistory(apiName) {
    const res = await axios.get(
      `${base}/dayData?${apiName}`
    )
    return res.data
  },

  async getWeekHistory(apiName) {
    const res = await axios.get(
      `${base}/weekData?${apiName}`
    )
    return res.data
  },

  async getMonthHistory(apiName) {
    const res = await axios.get(
      `${base}/monthData?${apiName}`
    )
    return res.data
  },

  async getYearHistory(apiName) {
    const res = await axios.get(
      `${base}/yearData?${apiName}`
    )
    return res.data
  }
}
