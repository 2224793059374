<template>
  <v-app-bar app color="primary" class="px-2">
    <v-toolbar-title
      class="headline"
      @click="$router.push('/stocks')"
      style="cursor: pointer"
    >
      <span class="black--text">tealeaf</span>
      <span class="font-weight-light black--text"></span>
    </v-toolbar-title>
    <!-- <v-btn class="ml-5" color="#757575" text small @click="$router.push('/about')"> about </v-btn> -->
    <v-btn class="ml-5" color="#757575" text small @click="$router.push('/contact')"> contact </v-btn>
    <v-spacer></v-spacer>

    <v-btn v-if="isAuthenticated" text class="black--text" @click="logout()"
      >Logout</v-btn
    >
    <v-btn v-else text class="black--text" @click="$router.push('/login')"
      >Login</v-btn
    >
    <!-- <v-menu offset-y v-if="isAuthenticated">
      <template v-slot:activator="{ on }">
        <v-btn color="black" icon v-on="on">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="store.user"
          key="Profile"
          @click="$router.push('/profile')"
        >
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="admin" key="Admin" @click="$router.push('/admin')">
          <v-list-item-title>Admin</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
  </v-app-bar>
</template>
<script>
import { store } from '@/store/store'
import UserService from '@/services/UserService.js'

export default {
  name: 'Header',
  data: () => ({
    store
  }),
  computed: {
    admin() {
      return (
        this.store.user &&
        this.store.user.role === 'Administrator'
      )
    },
    isAuthenticated() {
      return this.$cookies.isKey('token')
    }
  },
  methods: {
    logout() {
      UserService.logout()
    },
    // async setUpUserData() {
    //   const accessToken = this.$cookies.get("token")
    //   UserService.getUserData(accessToken, this.user.sub).then((result) => {
    //     store.userData = result
    //   })
    // },
  }
}
</script>
