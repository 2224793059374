<template>
  <v-footer color="primary lighten-1" padless>
    <v-row justify="center" no-gutters>
      <v-col class="py-1 text-center black--text" cols="12">
        Want access to more stocks?
        <v-btn
          color="black"
          text
          rounded
          class="my-2 mx-5"
          elevation="1"
          @click="upgrade"
        >
          Upgrade
        </v-btn>
      </v-col>
      <v-col class="secondary lighten-2 py-1 text-center black--text" cols="12">
        {{ new Date().getFullYear() }} — <strong>tealeaf</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { store } from '../store/store'
export default {
  data: () => ({
    store
  }),
  methods: {
    upgrade() {
      this.$router.push('/upgrade')

      // if(typeof this.store.user.email !== 'undefined' && this.store.user.email.length > 3) {
      //   this.$router.push('/upgrade')
      // }
      // else {
      //   this.$router.push('/login')
      // }
    }
  }
};
</script>