<template>
  <v-card
    align="center"
    justify="center"
    class="mx-auto mt-2"
    :color="value ? 'light-green accent-4' : 'red'"
    dark
    max-width="400"
  >
    <!-- <v-card-title>
      <span class="title">{{stocksymbol}}</span>
    </v-card-title> -->

    <v-card-text class="headline pt-7">
      <p class="display-1 text--white font-weight-bold">
        {{stocksymbol}}
      </p>
      <!-- <v-sparkline
        :value="graph"
        height="100"
        padding="24"
        stroke-linecap="round"
        smooth
      ></v-sparkline> -->
      {{ value ? "Going up" : "Going down" }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
      value: Number,
      stocksymbol: String,
      timeInterval: String
  },
  data: () => ({
    graph: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0]
  }),
  created() {
  },
  methods: {},
};
</script>