<template>
  <v-container>
    <PredictionTime class="mt-2" :timeFromDB="timeFromDB" />
    <p v-if="timeFromDB">
      <strong>Disclaimer: </strong>For informational purposes only. The
      information on this site should not be used to make financial decisions.
    </p>
    <v-row>
      <v-col
        :lg="4"
        :md="6"
        :cols="12"
        v-for="stock in stocks"
        :key="stock.symbol"
      >
        <StockCard
          :value="stock.value"
          :stocksymbol="stock.symbol"
          timeInterval="9:30-10:00"
        />
      </v-col>
    </v-row>
    <div class="text-center mt-5">
      <v-progress-circular
        v-if="!timeFromDB"
        indeterminate
        :size="200"
        :width="5"
        color="grey"
      ></v-progress-circular>
    </div>
  </v-container>
</template>
<script>
import DataService from "../services/DataService";
import UserService from "../services/UserService";
import StockCard from "../components/StockCard";
import PredictionTime from "../components/PredictionTime";
import { store } from "../store/store";
export default {
  name: "home",
  components: {
    StockCard,
    PredictionTime,
  },
  data: () => ({
    store,
    stocks: [],
    allStocks: [],
    timeFromDB: "",
  }),
  created() {},
  async mounted() {
    if (this.$cookies.isKey("token")) {
      await UserService.getUserData(this.$cookies.get("token")).then(
        (response) => {
          this.store.isAuthenticated = true;
          this.store.user = response;
        }
      );
    }
    await DataService.getCurrentData(this.$cookies.get("token")).then(
      (response) => {
        this.stocks = response.stocks;
        this.timeFromDB = response.date;
        this.$emit("rerender", 1);
      }
    );
  },
  updated() {},
  methods: {
    calculateAvailableStocks() {
      let availableStocks = [];
      if (this.store.user && this.store.user.authorized_symbols) {
        availableStocks = this.store.user.authorized_symbols;
      }
      this.allStocks.forEach((stock) => {
        if (!this.stocks.includes(stock)) {
          if (
            this.isNewUser() ||
            stock.symbol === "SPX" ||
            stock.symbol === "GME" ||
            availableStocks.includes(stock.symbol)
          ) {
            this.stocks.push(stock);
          }
        }
      });
    },
    isNewUser() {
      if (typeof this.store.user.signup_date === "undefined") {
        return false;
      }
      let signup_date = new Date(this.store.user.signup_date);
      const dayAgo = new Date().getTime() - 60 * 60 * 24 * 1000;
      if (signup_date > dayAgo) {
        return true;
      }
      return false;
    },
  },
};
</script>
