import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: 'white',
            secondary: '#9E9E9E',
            accent: colors.indigo.base, // #3F51B5
          },
        },
      },
});
